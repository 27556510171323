import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const liminaire = () => (
  <Layout>
    <SEO title="Dernières oeillades - Pauci Electi Sunt" />
    <h3 className='underline-title'>Pauci Electi Sunt (1)</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Cèdres du Liban <br />
        <span className="par-starting"></span>témoins millénaires <br />
        Roches sédentaires <br />
        <span className="par-starting"></span>vous qui le vîtes <br />
        parlez-en vous<br />
      </p>
      <p>
        Nos yeux refusent de voir<br />
        Nos oreilles évitent d'entendre<br />
        Malgré sa présence fracassante! <br />
      </p>
      <p>
        L'historique Nomade <br />
        Le Nomade au cou de qui <br />
        Se lient les destins humains <br />
      </p>
      <p>
        Il se rachète <br />
        Il va son chemin du Destin <br />
        Il clame le début de la fin <br />
      </p>
      <p>
        À chaque action se fait le miracle<br />
        À chaque réaction s'accomplit le prophétie<br />
        À chaque choc jaillit la puissance<br />
        À chaque coup s'étend la parcelle <br />
      </p>
      <p>
        Ils n'en croient pas <br />
        C'est plus fort qu'eux<br />
        Ils ne peuvent pas comprendre <br />
        C'est trop clair<br />
        Ils préfèrent des choses obscures<br />
      </p>
      <p>
        Il faut que cela arrive <br />
        Sans leur compréhension <br />
      </p>
      <p>
        Il a été écrit ainsi <br />
        Ils sont tous appelés <br />
        Ils ne sont pas tous élus <br />
      </p>
      <p>
        Ils ne comprendront jamais <br />
      </p>
      <p>
        Pardonnez-leur<br />
      </p>
    </div>
    <hr/>
    <p className="font-italic">(1) Peu est élu</p>
    <br />
    <br />
    <Link to="/dernieres-oeillades-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default liminaire
